import { render, staticRenderFns } from "./BlockchainChooser.vue?vue&type=template&id=f12fd994&scoped=true&"
import script from "./BlockchainChooser.vue?vue&type=script&lang=ts&"
export * from "./BlockchainChooser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f12fd994",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QSelect,QChip,QIcon,QTooltip,QItem,QItemSection,QItemLabel});
